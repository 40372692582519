import { Box, Spinner } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import * as React from 'react';

import { apiClient, setClientToken } from '@/http/api-client';

const AuthWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { data: sessionData, status } = useSession();
	const router = useRouter();

	const token = sessionData?.token ?? '';
	const pathname = router.pathname;
	const pathnameSplit = pathname.split('/');

	React.useEffect(() => {
		if (
			status === 'unauthenticated' &&
			pathnameSplit.length > 0 &&
			![
				'login',
				'pendaftaran',
				'forgot-password',
				'reset-password',
				'lupa-email',
				'dashboard',
				'referensi-belanja',
				'referensi-kegiatan',
				'master-data',
				'aktivasi',
				'',
			].includes(pathnameSplit[1])
		) {
			router.replace('/login');
		}
	}, [pathnameSplit, router, status]);

	if (status !== 'loading') {
		setClientToken(apiClient, token);
	}

	if (status === 'loading') {
		return (
			<Box w="100vw" h="100vh" display="grid" placeItems="center">
				<Spinner />
			</Box>
		);
	}

	return <>{children}</>;
};

export default AuthWrapper;
